// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { ThreeDots } from 'react-loader-spinner'; // Spinner
// import Sidebar from "../../components/sidebar/Sidebar";
// import SearchBar from "../../components/sidebar/SearchBar";
// import ProjectCreditDebitReportPreview from "./ProjectCreditDebitReportPreview";

// function ProjectCreditDebitReport({ handleLogout, username }) {
//     const [isLoading, setIsLoading] = useState(false);
//     const [ledgerEntries, setLedgerEntries] = useState([]); // Combine credit and debit entries
//     const [projects, setProjects] = useState([]);
//     const [selectedProject, setSelectedProject] = useState('');
//     const [monthlySummary, setMonthlySummary] = useState([]);

//     useEffect(() => {
//         fetchProjects();
//     }, []);

//     useEffect(() => {
//         if (selectedProject) {
//             fetchCreditDebit(selectedProject);
//         }
//     }, [selectedProject]);

//     const fetchCreditDebit = async (projectId) => {
//         setIsLoading(true);
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/projectcreditdebit/${projectId}`);
//             const formattedData = formatLedgerData(response.data);
//             setLedgerEntries(formattedData); // Set the combined entries in state
//             setMonthlySummary(calculateMonthlySummary(formattedData)); // Calculate the monthly summary
//             console.log("Credit/Debit data:", response.data);
//         } catch (error) {
//             console.error("Error fetching ledger entries:", error);
//             toast.error("Failed to fetch ledger entries");
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const fetchProjects = async () => {
//         setIsLoading(true);
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/projects`);
//             setProjects(response.data);
//         } catch (error) {
//             console.error("Error fetching projects:", error);
//             toast.error("Failed to fetch projects");
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const handleProjectChange = (projectId) => {
//         setSelectedProject(projectId);
//     };

//     // Function to format date strings to "Month Year" (e.g., "September 2024")
//     const getMonthYear = (dateString) => {
//         const date = new Date(dateString);
//         const options = { year: 'numeric', month: 'long' };
//         return date.toLocaleDateString('en-US', options);
//     };

//     // Format ledger data by adding month/year info
//     const formatLedgerData = (data) => {
//         return data.map((entry) => ({
//             ...entry,
//             monthYear: getMonthYear(entry.date)
//         }));
//     };

//     // Function to calculate the monthly summary of credit, debit, and balance
//     const calculateMonthlySummary = (data) => {
//         const summary = {};

//         data.forEach(entry => {
//             const monthYear = entry.monthYear;
//             if (!summary[monthYear]) {
//                 summary[monthYear] = { credit: 0, debit: 0 };
//             }

//             if (entry.type === 'Credit') {
//                 summary[monthYear].credit += entry.amount;
//             } else if (entry.type === 'Debit') {
//                 summary[monthYear].debit += entry.amount;
//             }
//         });

//         return Object.keys(summary).map(monthYear => {
//             const { credit, debit } = summary[monthYear];
//             return {
//                 monthYear,
//                 credit,
//                 debit,
//                 balance: credit - debit
//             };
//         });
//     };

//     return (
//         <div className="row">
//             <div className="col-xl-12">
//                 <div style={{ borderRadius: "20px", border: "1px solid #218380" }} className='overflow-hidden'>
//                     <div style={{ backgroundColor: "#218380" }} className="row no-gutters align-items-center p-3">
//                         <div className="col">
//                             <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
//                                 <div className="nunito text-white">Petty Cash Report</div>
//                                 <div className="d-flex gap-3">
//                                     <div className='d-flex align-items-center justify-content-center gap-2'>
//                                         <label className='nunito text-white p-0 m-0'>Project:</label>
//                                         <select
//                                             className="button_details overflow-hidden"
//                                             value={selectedProject}
//                                             onChange={(e) => handleProjectChange(e.target.value)}
//                                         >
//                                             <option value="">Select Project</option>
//                                             {projects.map(proj => (
//                                                 <option key={proj.id} value={proj.id}>{proj.projectName}</option>
//                                             ))}
//                                         </select>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <hr className='m-0 p-0' />
//                     <div className=''>
//                         <div className="card-body">
//                             <div className="" style={{ maxHeight: "550px", overflowY: "auto" }}>
//                                 {isLoading ? (
//                                     <div className="d-flex justify-content-center align-items-center">
//                                         <ThreeDots color="#00BFFF" height={80} width={80} />
//                                     </div>
//                                 ) : (
//                                     <>
//                                         <h3>Monthly Summary</h3>
//                                         <table className="table table-bordered" style={{ width: "100%" }}>
//                                             <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
//                                                 <tr>
//                                                     <th>Month-Year</th>
//                                                     <th>Total Credit</th>
//                                                     <th>Total Debit</th>
//                                                     <th>Balance</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {monthlySummary.length === 0 ? (
//                                                     <tr>
//                                                         <td colSpan="4" className="text-center">No Monthly Summary.</td>
//                                                     </tr>
//                                                 ) : (
//                                                     monthlySummary.map((summary, index) => (
//                                                         <tr key={index}>
//                                                             <td>{summary.monthYear}</td>
//                                                             <td>{summary.credit}</td>
//                                                             <td>{summary.debit}</td>
//                                                             <td>{summary.balance}</td>
//                                                         </tr>
//                                                     ))
//                                                 )}
//                                             </tbody>
//                                         </table>
//                                     </>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default ProjectCreditDebitReport;




// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { ThreeDots } from 'react-loader-spinner'; // Spinner

// function ProjectCreditDebitReport({ handleLogout, username }) {
//     const [isLoading, setIsLoading] = useState(false);
//     const [ledgerEntries, setLedgerEntries] = useState([]); // Combine credit and debit entries
//     const [projects, setProjects] = useState([]);
//     const [selectedProject, setSelectedProject] = useState('');
//     const [monthlySummary, setMonthlySummary] = useState([]);
//     const [selectedMonth, setSelectedMonth] = useState(''); // For month filtering
//     const [selectedYear, setSelectedYear] = useState(''); // For year filtering
//     const [grandTotals, setGrandTotals] = useState({}); // State for grand totals


//     useEffect(() => {
//         fetchProjects();
//     }, []);

//     useEffect(() => {
//         if (selectedProject) {
//             fetchCreditDebit(selectedProject);
//         }
//     }, [selectedProject, selectedMonth, selectedYear]); // Fetch data again when month or year changes

//     const fetchCreditDebit = async (projectId) => {
//         setIsLoading(true);
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/projectcreditdebit/${projectId}`);
//             let formattedData = formatLedgerData(response.data);
//             // Apply filtering by month and year
//             if (selectedMonth || selectedYear) {
//                 formattedData = formattedData.filter((entry) => {
//                     const entryDate = new Date(entry.date);
//                     const entryMonth = entryDate.getMonth() + 1; // getMonth returns 0-indexed month
//                     const entryYear = entryDate.getFullYear();
//                     return (
//                         (!selectedMonth || entryMonth === selectedMonth) &&
//                         (!selectedYear || entryYear === selectedYear)
//                     );
//                 });
//             }
//             setLedgerEntries(formattedData); // Set the filtered entries in state
//             setMonthlySummary(calculateMonthlySummary(formattedData)); // Calculate the monthly summary
//             console.log("Filtered Credit/Debit data:", formattedData);
//         } catch (error) {
//             console.error("Error fetching ledger entries:", error);
//             toast.error("Failed to fetch ledger entries");
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const fetchProjects = async () => {
//         setIsLoading(true);
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/projects`);
//             setProjects(response.data);
//         } catch (error) {
//             console.error("Error fetching projects:", error);
//             toast.error("Failed to fetch projects");
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const handleProjectChange = (projectId) => {
//         setSelectedProject(projectId);
//     };

//     // Function to format date strings to "Month Year" (e.g., "September 2024")
//     const getMonthYear = (dateString) => {
//         const date = new Date(dateString);
//         const options = { year: 'numeric', month: 'long' };
//         return date.toLocaleDateString('en-US', options);
//     };

//     // Format ledger data by adding month/year info
//     const formatLedgerData = (data) => {
//         return data.map((entry) => ({
//             ...entry,
//             monthYear: getMonthYear(entry.date)
//         }));
//     };

//     // Function to calculate the monthly summary of credit, debit, and balance
//     const calculateMonthlySummary = (data) => {
//         const summary = {};

//         data.forEach(entry => {
//             const monthYear = entry.monthYear;
//             if (!summary[monthYear]) {
//                 summary[monthYear] = { credit: 0, debit: 0 };
//             }

//             if (entry.type === 'Credit') {
//                 summary[monthYear].credit += entry.amount;
//             } else if (entry.type === 'Debit') {
//                 summary[monthYear].debit += entry.amount;
//             }
//         });

//         return Object.keys(summary).map(monthYear => {
//             const { credit, debit } = summary[monthYear];
//             return {
//                 monthYear,
//                 credit,
//                 debit,
//                 balance: credit - debit
//             };
//         });
//     };

//     useEffect(() => {
//         calculateTotals();
//         console.log("grandTotals",grandTotals)
//     }, [monthlySummary]);


//     const calculateTotals = () => {
//         const totals = monthlySummary.reduce((acc, record) => {
//             acc.totalcredit += record.credit ? parseFloat(record.credit) : 0;
//             acc.totaldebit += record.debit ? parseFloat(record.debit) : 0;
//             return acc;
//         }, {
//             totalcredit: 0,
//             totaldebit: 0,
//         });

//         setGrandTotals(totals);
//     };


//     return (
//         <div className="row">
//             <div className="col-xl-12">
//                 <div style={{ borderRadius: "20px", border: "1px solid #218380" }} className='overflow-hidden'>
//                     <div style={{ backgroundColor: "#218380" }} className="row no-gutters align-items-center p-3">
//                         <div className="col">
//                             <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
//                                 <div className="nunito text-white">Petty Cash Report</div>
//                                 <div className="d-flex gap-3">
//                                     <div className='d-flex align-items-center justify-content-center gap-2'>
//                                         <label className='nunito text-white p-0 m-0'>Project:</label>
//                                         <select
//                                             className="button_details overflow-hidden"
//                                             value={selectedProject}
//                                             onChange={(e) => handleProjectChange(e.target.value)}
//                                         >
//                                             <option value="">Select Project</option>
//                                             {projects.map(proj => (
//                                                 <option key={proj.id} value={proj.id}>{proj.projectName}</option>
//                                             ))}
//                                         </select>
//                                     </div>
//                                     <div className="d-flex align-items-center justify-content-center gap-2">
//                                         <label className='nunito text-white p-0 m-0'>Filter:</label>
//                                         <select
//                                             className="button_details"
//                                             value={selectedMonth}
//                                             onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
//                                         >
//                                             <option value="">Select Month</option>
//                                             {Array.from({ length: 12 }, (_, i) => (
//                                                 <option key={i} value={i + 1}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
//                                             ))}
//                                         </select>
//                                         <select
//                                             className="button_details"
//                                             value={selectedYear}
//                                             onChange={(e) => setSelectedYear(parseInt(e.target.value))}
//                                         >
//                                             <option value="">Select Year</option>
//                                             {Array.from({ length: 10 }, (_, i) => (
//                                                 <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
//                                             ))}
//                                         </select>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <hr className='m-0 p-0' />
//                     <div className=''>
//                         <div className="card-body">
//                             <div className="" style={{ maxHeight: "550px", overflowY: "auto" }}>
//                                 {isLoading ? (
//                                     <div className="d-flex justify-content-center align-items-center">
//                                         <ThreeDots color="#00BFFF" height={80} width={80} />
//                                     </div>
//                                 ) : (
//                                     <>
//                                         <h3>Monthly Summary</h3>
//                                         <table className="table table-bordered" style={{ width: "100%" }}>
//                                             <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
//                                                 <tr>
//                                                     <th>Month-Year</th>
//                                                     <th>Total Credit</th>
//                                                     <th>Total Debit</th>
//                                                     <th>Balance</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {monthlySummary.length === 0 ? (
//                                                     <tr>
//                                                         <td colSpan="4" className="text-center">No Monthly Summary.</td>
//                                                     </tr>
//                                                 ) : (
//                                                     monthlySummary.map((summary, index) => (
//                                                         <tr key={index}>
//                                                             <td>{summary.monthYear}</td>
//                                                             <td>{summary.credit}</td>
//                                                             <td>{summary.debit}</td>
//                                                             <td>{summary.balance}</td>
//                                                         </tr>
//                                                     ))
//                                                 )}
//                                             </tbody>
//                                         </table>
//                                     </>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default ProjectCreditDebitReport;



// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { ThreeDots } from 'react-loader-spinner'; // Spinner

// function ProjectCreditDebitReport({ handleLogout, username }) {
//     const [isLoading, setIsLoading] = useState(false);
//     const [ledgerEntries, setLedgerEntries] = useState([]); // Combined credit and debit entries
//     const [projects, setProjects] = useState([]);
//     const [selectedProject, setSelectedProject] = useState('');
//     const [monthlySummary, setMonthlySummary] = useState([]);
//     const [selectedMonth, setSelectedMonth] = useState(''); // For month filtering
//     const [selectedYear, setSelectedYear] = useState(''); // For year filtering
//     const [grandTotals, setGrandTotals] = useState({ totalcredit: 0, totaldebit: 0 }); // State for grand totals

//     useEffect(() => {
//         fetchProjects();
//     }, []);

//     useEffect(() => {
//         if (selectedProject) {
//             fetchCreditDebit(selectedProject);
//         }
//     }, [selectedProject, selectedMonth, selectedYear]); // Fetch data again when month or year changes

//     const fetchCreditDebit = async (projectId) => {
//         setIsLoading(true);
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/projectcreditdebit/${projectId}`);
//             let formattedData = formatLedgerData(response.data);

//             // Apply filtering by month and year
//             if (selectedMonth || selectedYear) {
//                 formattedData = formattedData.filter((entry) => {
//                     const entryDate = new Date(entry.date);
//                     const entryMonth = entryDate.getMonth() + 1; // getMonth returns 0-indexed month
//                     const entryYear = entryDate.getFullYear();
//                     return (
//                         (!selectedMonth || entryMonth === parseInt(selectedMonth)) &&
//                         (!selectedYear || entryYear === parseInt(selectedYear))
//                     );
//                 });
//             }

//             setLedgerEntries(formattedData); // Set the filtered entries in state
//             const summary = calculateMonthlySummary(formattedData);
//             setMonthlySummary(summary); // Set monthly summary
//         } catch (error) {
//             console.error("Error fetching ledger entries:", error);
//             toast.error("Failed to fetch ledger entries");
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const fetchProjects = async () => {
//         setIsLoading(true);
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/projects`);
//             setProjects(response.data);
//         } catch (error) {
//             console.error("Error fetching projects:", error);
//             toast.error("Failed to fetch projects");
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const handleProjectChange = (projectId) => {
//         setSelectedProject(projectId);
//     };

//     // Function to format date strings to "Month Year" (e.g., "September 2024")
//     const getMonthYear = (dateString) => {
//         const date = new Date(dateString);
//         const options = { year: 'numeric', month: 'long' };
//         return date.toLocaleDateString('en-US', options);
//     };

//     // Format ledger data by adding month/year info
//     const formatLedgerData = (data) => {
//         return data.map((entry) => ({
//             ...entry,
//             monthYear: getMonthYear(entry.date),
//         }));
//     };

//     // Function to calculate the monthly summary of credit, debit, and balance
//     const calculateMonthlySummary = (data) => {
//         const summary = {};

//         data.forEach(entry => {
//             const monthYear = entry.monthYear;
//             if (!summary[monthYear]) {
//                 summary[monthYear] = { credit: 0, debit: 0 };
//             }

//             if (entry.type === 'Credit') {
//                 summary[monthYear].credit += entry.amount;
//             } else if (entry.type === 'Debit') {
//                 summary[monthYear].debit += entry.amount;
//             }
//         });

//         return Object.keys(summary).map(monthYear => {
//             const { credit, debit } = summary[monthYear];
//             return {
//                 monthYear,
//                 credit,
//                 debit,
//                 balance: credit - debit
//             };
//         });
//     };

//     useEffect(() => {
//         if (monthlySummary.length > 0) {
//             calculateTotals();
//         }
//     }, [monthlySummary]);

//     const calculateTotals = () => {
//         if (monthlySummary.length === 0) {
//             setGrandTotals({ totalcredit: 0, totaldebit: 0 }); // Reset to 0 if no data
//         } else {
//             const totals = monthlySummary.reduce((acc, record) => {
//                 acc.totalcredit += record.credit ? parseFloat(record.credit) : 0;
//                 acc.totaldebit += record.debit ? parseFloat(record.debit) : 0;
//                 return acc;
//             }, {
//                 totalcredit: 0,
//                 totaldebit: 0,
//             });

//             setGrandTotals(totals);
//         }
//     };

//     return (
//         <div className="row">
//             <div className="col-xl-12">
//                 <div style={{ borderRadius: "20px", border: "1px solid #218380" }} className='overflow-hidden'>
//                     <div style={{ backgroundColor: "#218380" }} className="row no-gutters align-items-center p-3">
//                         <div className="col">
//                             <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
//                                 <div className="nunito text-white">Petty Cash Report</div>
//                                 <div className="d-flex gap-3">
//                                     <div className='d-flex align-items-center justify-content-center gap-2'>
//                                         <label className='nunito text-white p-0 m-0'>Project:</label>
//                                         <select
//                                             className="button_details overflow-hidden"
//                                             value={selectedProject}
//                                             onChange={(e) => handleProjectChange(e.target.value)}
//                                         >
//                                             <option value="">Select Project</option>
//                                             {projects.map(proj => (
//                                                 <option key={proj.id} value={proj.id}>{proj.projectName}</option>
//                                             ))}
//                                         </select>
//                                     </div>
//                                     <div className="d-flex align-items-center justify-content-center gap-2">
//                                         <label className='nunito text-white p-0 m-0'>Filter:</label>
//                                         <select
//                                             className="button_details"
//                                             value={selectedMonth}
//                                             onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
//                                         >
//                                             <option value="">Select Month</option>
//                                             {Array.from({ length: 12 }, (_, i) => (
//                                                 <option key={i} value={i + 1}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
//                                             ))}
//                                         </select>
//                                         <select
//                                             className="button_details"
//                                             value={selectedYear}
//                                             onChange={(e) => setSelectedYear(parseInt(e.target.value))}
//                                         >
//                                             <option value="">Select Year</option>
//                                             {Array.from({ length: 10 }, (_, i) => (
//                                                 <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
//                                             ))}
//                                         </select>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <hr className='m-0 p-0' />
//                     <div className=''>
//                         <div className="card-body">
//                             <div className="" style={{ maxHeight: "550px", overflowY: "auto" }}>
//                                 {isLoading ? (
//                                     <div className="d-flex justify-content-center align-items-center">
//                                         <ThreeDots color="#00BFFF" height={80} width={80} />
//                                     </div>
//                                 ) : (
//                                     <>
//                                         <h3>Monthly Summary</h3>
//                                         <table className="table table-bordered" style={{ width: "100%" }}>
//                                             <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
//                                                 <tr>
//                                                     <th>Month-Year</th>
//                                                     <th>Total Credit</th>
//                                                     <th>Total Debit</th>
//                                                     <th>Balance</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {monthlySummary.length === 0 ? (
//                                                     <tr>
//                                                         <td colSpan="4" className="text-center">No Monthly Summary.</td>
//                                                     </tr>
//                                                 ) : (
//                                                     monthlySummary.map((summary, index) => (
//                                                         <tr key={index}>
//                                                             <td>{summary.monthYear}</td>
//                                                             <td>{summary.credit}</td>
//                                                             <td>{summary.debit}</td>
//                                                             <td>{summary.balance}</td>
//                                                         </tr>
//                                                     ))
//                                                 )}
//                                             </tbody>
//                                             <tfoot>
//                                                     <tr>
//                                                         <th>Totals</th>
//                                                         <th className='text-end'>&#x20B9;{grandTotals.totalcredit != null ? grandTotals.totalcredit.toFixed(2) : '0.00'}</th>
//                                                         <th className='text-end'>&#x20B9;{grandTotals.totaldebit != null ? grandTotals.totaldebit.toFixed(2) : '0.00'}</th>
//                                                         <th></th>
//                                                     </tr>
//                                                 </tfoot>
//                                         </table>
//                                     </>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default ProjectCreditDebitReport;


import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner'; // Spinner
import ProjectCreditDebitReportPreview from "./ProjectCreditDebitReportPreview";
import Sidebar from "../../components/sidebar/Sidebar";
import SearchBar from "../../components/sidebar/SearchBar";

function ProjectCreditDebitReport({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [ledgerEntries, setLedgerEntries] = useState([]); // Combined credit and debit entries
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');
    const [monthlySummary, setMonthlySummary] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(''); // For month filtering
    const [selectedYear, setSelectedYear] = useState(''); // For year filtering
    const [grandTotals, setGrandTotals] = useState({ totalcredit: 0, totaldebit: 0 }); // State for grand totals
    // sidebar SearchBar 
    const [showSidebar, setShowSidebar] = useState(true); // State to control sidebar visibility
    const [showSearchBar, setShowSearchBar] = useState(true); // State to control search bar visibility
    const [detailsVisible, setDetailsVisible] = useState(false); // State for details modal
    const [selectedRecord, setSelectedRecord] = useState(null); // State for selected record

    useEffect(() => {
        fetchProjects();
    }, []);

    useEffect(() => {
        if (selectedProject) {
            fetchCreditDebit(selectedProject);
        }
    }, [selectedProject, selectedMonth, selectedYear]); // Fetch data again when month or year changes

    const fetchCreditDebit = async (projectId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/projectcreditdebit/${projectId}`);
            let formattedData = formatLedgerData(response.data);

            // Apply filtering by month and year
            if (selectedMonth || selectedYear) {
                formattedData = formattedData.filter((entry) => {
                    const entryDate = new Date(entry.date);
                    const entryMonth = entryDate.getMonth() + 1; // getMonth returns 0-indexed month
                    const entryYear = entryDate.getFullYear();
                    return (
                        (!selectedMonth || entryMonth === parseInt(selectedMonth)) &&
                        (!selectedYear || entryYear === parseInt(selectedYear))
                    );
                });
            }

            setLedgerEntries(formattedData); // Set the filtered entries in state
            const summary = calculateMonthlySummary(formattedData);
            setMonthlySummary(summary); // Set monthly summary

            // If no data for the current selection, reset grand totals to zero
            if (summary.length === 0) {
                setGrandTotals({ totalcredit: 0, totaldebit: 0 });
            }
        } catch (error) {
            console.error("Error fetching ledger entries:", error);
            toast.error("Failed to fetch ledger entries");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchProjects = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/projects`);
            setProjects(response.data);
        } catch (error) {
            console.error("Error fetching projects:", error);
            toast.error("Failed to fetch projects");
        } finally {
            setIsLoading(false);
        }
    };

    const handleProjectChange = (projectId) => {
        setSelectedProject(projectId);
    };

    // Function to format date strings to "Month Year" (e.g., "September 2024")
    const getMonthYear = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long' };
        return date.toLocaleDateString('en-US', options);
    };

    // Format ledger data by adding month/year info
    const formatLedgerData = (data) => {
        return data.map((entry) => ({
            ...entry,
            monthYear: getMonthYear(entry.date),
        }));
    };

    // Function to calculate the monthly summary of credit, debit, and balance
    const calculateMonthlySummary = (data) => {
        const summary = {};

        data.forEach(entry => {
            const monthYear = entry.monthYear;
            if (!summary[monthYear]) {
                summary[monthYear] = { credit: 0, debit: 0 };
            }

            if (entry.type === 'Credit') {
                summary[monthYear].credit += entry.amount;
            } else if (entry.type === 'Debit') {
                summary[monthYear].debit += entry.amount;
            }
        });

        return Object.keys(summary).map(monthYear => {
            const { credit, debit } = summary[monthYear];
            return {
                monthYear,
                credit,
                debit,
                balance: credit - debit
            };
        });
    };

    useEffect(() => {
        if (monthlySummary.length > 0) {
            calculateTotals();
        } else {
            setGrandTotals({ totalcredit: 0, totaldebit: 0 }); // Reset grand totals to zero if no summary
        }
    }, [monthlySummary]);

    const calculateTotals = () => {
        if (monthlySummary.length === 0) {
            setGrandTotals({ totalcredit: 0, totaldebit: 0 }); // Reset to 0 if no data
        } else {
            const totals = monthlySummary.reduce((acc, record) => {
                acc.totalcredit += record.credit ? parseFloat(record.credit) : 0;
                acc.totaldebit += record.debit ? parseFloat(record.debit) : 0;
                return acc;
            }, {
                totalcredit: 0,
                totaldebit: 0,
            });

            setGrandTotals(totals);
        }
    };

    // const handleDetails = () => {
    //     // Find the selected project object from projects
    //     console.log("Selected Project ID:", selectedProject); // Debug log
    //     const selectedProjectData = projects.find(project => project.id === selectedProject);
    //     console.log("selectedProjectData",selectedProjectData)
    //     setSelectedRecord({
    //         // Include the projectName from the selected project
    //         projectName: selectedProjectData ? selectedProjectData.name : '',
    //         status: monthlySummary.length > 0 ? monthlySummary[0].status : '',
    //         recordData: monthlySummary,
    //         selectedYear
    //     });

    //     setDetailsVisible(true);
    //     setShowSidebar(false);
    //     setShowSearchBar(false);
    // };
    const handleDetails = () => {
        console.log("Selected Project ID:", selectedProject);
        console.log("Projects Array:", projects); // Log the projects array to inspect

        if (projects.length === 0) {
            toast.error("No projects available");
            return; // Exit if there are no projects
        }

        const selectedId = Number(selectedProject); // Convert selectedProject to a number
        const selectedProjectData = projects.find(project => project.id === selectedId); // Match with the numeric id

        console.log("selectedProjectData", selectedProjectData); // Log to check if data was found

        // Update the record setting to correctly access properties
        setSelectedRecord({
            projectName: selectedProjectData ? selectedProjectData.projectName : '', // Correctly access projectName
            status: monthlySummary.length > 0 ? monthlySummary[0].status : '', // Make sure this status exists in your monthlySummary
            recordData: monthlySummary,
            selectedYear: selectedYear // Set selectedYear correctly
        });

        setDetailsVisible(true);
        setShowSidebar(false);
        setShowSearchBar(false);
    };



    const handleClosePreview = () => {
        setShowSidebar(true); // Show sidebar when closing preview
        setShowSearchBar(true); // Show search bar when closing preview
        setDetailsVisible(false); // Hide details
    };

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            {showSidebar && <Sidebar />}
            <div className='w-100'>
                {showSearchBar && <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />}
                <div className="container-fluid">
                    <ToastContainer />
                    {detailsVisible ? (
                        <ProjectCreditDebitReportPreview
                            record={selectedRecord}
                            onClose={handleClosePreview}
                        />
                    ) : (
                        <div className="row">
                            <div className="col-xl-12">
                                <div style={{ borderRadius: "20px", border: "1px solid #218380" }} className='overflow-hidden'>
                                    <div style={{ backgroundColor: "#218380" }} className="row no-gutters align-items-center p-3">
                                        <div className="col">
                                            <div className="text-xs font-weight-bold text-white text-uppercase d-flex align-items-center justify-content-between" style={{ fontSize: '1.5rem' }}>
                                                <div className="nunito text-white">Monthly Report</div>
                                                <div className="d-flex gap-3">
                                                    <div className='d-flex align-items-center justify-content-center gap-2'>
                                                        <label className='nunito text-white p-0 m-0'>Project:</label>
                                                        <select
                                                            className="button_details overflow-hidden"
                                                            value={selectedProject}
                                                            onChange={(e) => handleProjectChange(e.target.value)}
                                                        >
                                                            <option value="">Select Project</option>
                                                            {projects.map(proj => (
                                                                <option key={proj.id} value={proj.id}>{proj.projectName}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                                        <label className='nunito text-white p-0 m-0'>Filter:</label>
                                                        <select
                                                            className="button_details"
                                                            value={selectedYear}
                                                            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                                                        >
                                                            <option value="">Select Year</option>
                                                            {Array.from({ length: 10 }, (_, i) => (
                                                                <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <button onClick={handleDetails} className="button_details">
                                                        PDF
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='m-0 p-0' />
                                    <div className=''>
                                        <div className="card-body">
                                            <div className="" style={{ maxHeight: "550px", overflowY: "auto" }}>
                                                {isLoading ? (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <ThreeDots color="#00BFFF" height={80} width={80} />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <table className="table table-bordered" style={{ width: "100%" }}>
                                                            <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                                <tr>
                                                                    <th>Month-Year</th>
                                                                    <th>Total Credit</th>
                                                                    <th>Total Debit</th>
                                                                    <th>Balance</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {monthlySummary.length === 0 ? (
                                                                    <tr>
                                                                        <td colSpan="4" className="text-center">No Monthly Summary.</td>
                                                                    </tr>
                                                                ) : (
                                                                    monthlySummary.map((summary, index) => (
                                                                        <tr key={index}>
                                                                            <td>{summary.monthYear}</td>
                                                                            <td className='text-end'>&#x20B9;{summary.credit != null ? summary.credit.toFixed(2) : '0.00'}</td>
                                                                            <td className='text-end'>&#x20B9;{summary.debit != null ? summary.debit.toFixed(2) : '0.00'}</td>
                                                                            <td className='text-end'>&#x20B9;{summary.balance != null ? summary.balance.toFixed(2) : '0.00'}</td>
                                                                        </tr>
                                                                    ))
                                                                )}
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <th>Totals</th>
                                                                    <th className="text-success text-end">&#x20B9;{grandTotals.totalcredit != null ? grandTotals.totalcredit.toFixed(2) : '0.00'}</th>
                                                                    <th className="text-danger text-end">&#x20B9;{grandTotals.totaldebit != null ? grandTotals.totaldebit.toFixed(2) : '0.00'}</th>
                                                                    <th className='text-end'>&#x20B9;{(grandTotals.totalcredit - grandTotals.totaldebit).toFixed(2)}</th>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ProjectCreditDebitReport;







